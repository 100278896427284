import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import MainPage from './pages/MainPage';
import ProjectPage from './pages/ProjectsPage';
import { Route, BrowserRouter as Router, withRouter } from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent';
import TilesComponent from './components/TilesComponent';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const HeaderComponentWithRoute = withRouter(props => <HeaderComponent {...props}/>);

const routing = (
    <Router>
        <div className="main">
            <HeaderComponentWithRoute />
            <Route exact path="/" component={MainPage} />
            {/*<Route path="/projects" component={ProjectPage} />
            <Route path="/about" component={AboutPage} />*/}
            <Route path="/contact" component={ContactPage} />
        </div>

        <TilesComponent />
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));