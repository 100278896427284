import React from 'react';
import "./ContactPage.scss";
import mail from "./mail.svg";

export default function ContactPage() {
    return (
        <div className="ContactPage">
            <p>
                If you have an enquiry, please email mark@kravinskiy.com
            </p>
        </div>
    );
}