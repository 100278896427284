import React from 'react';
import "./TilesComponent.scss";
import LinkedIn from './linkedin.svg';

export default function TilesComponent() {
    return (
        <div className="tiles">
            <div className="tiles__top">
                <a href="https://www.linkedin.com/in/mark-kravinskiy/" alt="Mark Kravinskiy LinkedIn">
                    <img src={LinkedIn} alt="Mark Kravinskiy LinkedIn" className="tiles__top__image" />
                </a>
            </div>

            <div className="tiles__bottom">
                Copyright 2020
                DEVOLOGY.IO Limited
            </div>
        </div>
    );
}