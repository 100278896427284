import "./HeaderComponent.scss";
import React from 'react';
import { NavLink} from 'react-router-dom';

export default function HeaderComponent(props) {

    return(
        <div className="Header">
        
            {
                props.location.pathname !== "/" &&
                <div className="Header__right">
                    Mark Kravinskiy
                </div>
            }

            <div className="Header__left">
                <NavLink className="Header__left__item" to="/" activeClassName="active" exact>
                    home
                </NavLink>

                {/*<NavLink className="Header__left__item" to="/projects" activeClassName="active">
                    projects
                </NavLink>

                <NavLink className="Header__left__item" to="/about" activeClassName="active">
                    about
                </NavLink>*/}

                <NavLink className="Header__left__item" to="/contact" activeClassName="active">
                    contact
                </NavLink>
            </div>

        </div>
    );
}