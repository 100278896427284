import React from 'react';

import "./MainPage.scss";

export default function MainPage() {
    return (
        <div>
            <div className="MainPage">
                <div className="MainPage__title">Mark Kravinskiy</div>

                <div className="MainPage__skills">
                    <span className="MainPage__skills__span">tech</span>
                    <span className="MainPage__skills__span">consulting</span>
                    <span className="MainPage__skills__span">modernization</span>
                </div>
            </div>
        </div>
    );     
}